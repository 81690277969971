// 'use strict'

import React, { Component } from "react";
import PropTypes from "prop-types"; 
// import SpeechRecognition from "react-speech-recognition"; 

// import { googleTranslate } from "../../utils/googleTranslate"; 

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition; 
const recognition = new SpeechRecognition();

recognition.continuous = true; 
recognition.lang = "en-US"; 

const propTypes = {
  // Props injected by SpeechRecognition
  transcript: PropTypes.string,
  resetTranscript: PropTypes.func,
  browserSupportsSpeechRecognition: PropTypes.bool
}

class Contact extends Component {
  constructor(props){
    super(props); 
    this.state = {
      // sourceLanguage: "", 
      // targetLanguage: "en", 
      name: "", 
      email: "", 
      message: "", 
      // translatedMessage: "", 
      // translated: false, 
      listening: false,
      speechDetected: false
    };

    this.handleChange = this.handleChange.bind(this); 
    // this.handleTranslation = this.handleTranslation.bind(this); 
    this.toggleListen = this.toggleListen.bind(this); 
    this.handleListen = this.handleListen.bind(this);
  }

  componentDidMount() {
    //? not really needed ?? 
  }

  componentWillUnmount() {
    recognition.stop(); 
  }

  handleChange(event) {
    const { name, email, value } = event.target
    this.setState({
        [name]: value, 
        [email]: value, 
        message: event.target.value 
    });
  }

  toggleListen() {
    this.setState({
      listening: !this.state.listening 
    }, this.handleListen); 
  }

  handleListen() {
    if (this.state.listening) {
      recognition.start(); 
      recognition.onend = function() {
        console.log('Speech recognition service disconnected');
      }
    } else {
      recognition.stop(); 
    }

    let finalTranscript = ""; 
    recognition.onresult = event => {
      // let interimTranscript = ""; 
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript; 
        if (event.results[i].isFinal) finalTranscript += transcript + " "; 
        // else interimTranscript += transcript; 
      }
    
      // ? How can we display interimTranscript, while finalTranscript is being processed? 
      this.setState({
        message: finalTranscript, 
        speechDetected: true
      }, console.log("message is:", this.state.message));
    }
  }

  // handleTranslation() {

  //   let { targetLanguage, message, translatedMessage } = this.state;

  //   googleTranslate.detectLanguage(message, (err, detection) => {
  //     if (detection.language !== targetLanguage) {
  //       this.setState(
  //         { sourceLanguage: detection.language, translated: true }, () => console.log(this.state.sourceLanguage, detection)
  //       ); 
  //     } else {
  //       alert("Your message is already in English!");
  //       this.setState({ sourceLanguage: targetLanguage }, () => console.log(this.state.sourceLanguage, detection))
  //     }
  //   }); 
    
  //   googleTranslate.translate(message, targetLanguage, (err, translation) => {
  //     translatedMessage = translation.translatedText;
  //     this.setState({ translatedMessage }, console.log("The requested translation is:", translatedMessage));
  //   }); 
  // }

  render() {
    const isListening = this.state.listening; 
    // const translated = this.state.translated; 
    const speechText = this.state.speechDetected; 

    return (
      <Row className="justify-content-center hero">
        <Col xs lg="6">
          <h2>Contact Me</h2>
          <p>Send me your message below. I will get back to you as soon as possible.</p>

          <Form>
            <Form.Group>
              <Row>
                  <Col>
                      <Form.Label>Message:</Form.Label>
                      {/* name is wrong */}
                      {speechText ? <Form.Control name="translation" as="textarea" rows="3" plaintext defaultValue={this.state.message} /> : <Form.Control name="message" as="textarea" rows="3" onChange={this.handleChange} />}
                  </Col>
              </Row>
              {/* ! needs to update value if the user makes edits (i.e., changes text, then hits translate again) */}
              {/* {translated ? <div><Form.Label>Translation:</Form.Label> <Form.Control name="translation" as="textarea" rows="3" plaintext defaultValue={this.state.translatedMessage} /></div> : ""} */}
            </Form.Group> 
              
            <Form.Group>
                <Row>
                    <Col>
                        <Form.Label>Your Name:</Form.Label>
                        <Form.Control name="name" type="text" placeholder="" onChange={this.handleChange} />
                    </Col>
                    <Col>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control name="email" type="email" placeholder="name@example.com" onChange={this.handleChange} />
                    </Col>
                </Row>

            </Form.Group>
            <ButtonToolbar>
              <Button className="mr-2" variant="primary" size ="lg" onClick={this.toggleListen}>{isListening ? "Listening" : "Record"}</Button>
              {/* <Button variant="dark" size="lg" onClick={this.handleTranslation}>Translate to English</Button> */}
              <Button variant="outline-dark" type="submit" size="lg">Send</Button>
            </ButtonToolbar>
          </Form>
        </Col>
      </Row>
    );
  }
}

Contact.propTypes = propTypes; 

// export default SpeechRecognition(Contact);
export default Contact; 