import React from 'react';

import "../../styles/common.css";
import "../../styles/homepage.css";
import "../../styles/resume.css";
import "../../styles/mediaqueries.css";

import * as ROUTES from '../../constants/routes';

function Landing() {

  return (
    <div className="background">
      <section style={{minHeight: "625px"}}>
        <article>
          <div className="container about-container" article="" style={{minHeight: "590px"}}>
            <h1>Aziza Ismail</h1>
            <h2>Founder and CEO of LiteraSeed</h2>
            <hr />
            <div className="img-container float-sm-right ml-4">
              <img src="img/selfie.jpg" alt="Aziza" className="img-fluid" />
            </div>
            <div className="vertical-spacing"></div>
            <p>
              Hi, my name is Aziza. I'm a <strong style={{color: "#F59292"}}>social impact-driven entrepreneur</strong> in <strong style={{color: "#F59292"}}>digital health</strong>. 
              As the daughter of immigrant parents from war-torn Afghanistan, a country
              where education and literacy has been historically low, I've always been
              particularly passionate about education. Seeing the first hand consequences of low-literacy  in my family,
              I became determined to create solutions that can help low-literate adults
              navigate everyday scenarios. Navigating healthcare is of particular importance, 
              where the consequence of error involves human safety. Following
              my passion for a safer and more accessible healthcare, inclusive of people
              with language and literacy barriers, I founded LiteraSeed.
            </p>
            <p><a href={ROUTES.ABOUT}>Learn more about my story...</a></p>
          </div>
        </article>
      </section>
    </div>
  );
}

export default Landing;