import React from "react"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'; 

const Banner = () => (
    <div className="jumbotron jumbotron-fluid bg-white mb-0 pt-4">
        <div className="container"> 
            <div className="ml-auto">
                <a href="https://github.com/Aziza-Ismail" style={{color: "#383838", marginRight: "6px"}}><FontAwesomeIcon icon={faGithub} size="lg" /> </a> 
                <a href="https://www.linkedin.com/in/azizaismail/" style={{color: "#383838"}}><FontAwesomeIcon icon={faLinkedin} size="lg" /></a>
            </div>
            <h1 style={{ fontFamily: "'Parisienne', cursive", fontSize: "3rem", fontWeight: "bold", color: "#505050"}} className="text-center">Aziza Ismail</h1>
        </div>
    </div>
); 

export default Banner; 