import React from 'react'; 
import { BrowserRouter as Router, Route } from "react-router-dom"; 

// all
import Banner from "./components/Banner"; 
import Navigation from "./components/Navigation"; 
import Footer from "./components/Footer"; 

import Landing from "./components/Landing"; 
import About from "./components/About"; 
import Portfolio from "./components/Portfolio"; 
import Resume from "./components/Resume"; 
// import Writings from "./components/Writings"; 
import Contact from "./components/Contact"; 

import * as ROUTES from "./constants/routes"; 

const AppRouter = () => (
    <Router>
        <div>
            <Banner /> 
            <Navigation />

            <Route exact path={ROUTES.LANDING} component={Landing} />
            <Route exact path={ROUTES.ABOUT} component={About} />
            <Route exact path={ROUTES.PORTFOLIO} component={Portfolio} />
            <Route exact path={ROUTES.RESUME} component={Resume} />
            {/* <Route exact path={ROUTES.WRITINGS} component={Writings} /> */}
            <Route exact path={ROUTES.CONTACT} component={Contact} />
            
            <Footer /> 
        </div> 
    </Router>
); 

export default AppRouter;