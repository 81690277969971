import React from "react"; 

import Container from "react-bootstrap/Container"; 
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button"; 

export default function Portfolio() {
   return (
      <Container fluid style={{ margin: '25px auto', width: '85%' }}>

         <h1>Portfolio</h1>
         <hr/>

         <Row>
            <Col>
               <Card style={{ width: '18rem', margin: "0 auto" }}>
                  <Card.Img variant="top" src="https://image.freepik.com/free-photo/blank-screen-modern-laptop-computer-with-mouse-small-plant-workspace-desktop_34933-320.jpg" />
                  <Card.Body>
                     <Card.Title>Patient Intake App</Card.Title>
                     <Card.Subtitle className="mb-2 text-muted"><strong>Technology:</strong> React.js, Bootstrap</Card.Subtitle>
                     <Card.Text className="p-0">Streamlining patient intake process with a tool that helps patients with language barriers describe what's wrong.</Card.Text>
                     <Button className="mr-2" href="#" variant="info">See demo</Button>
                     <Button href="#" variant="outline-info">Research</Button>
                  </Card.Body>
               </Card>
            </Col>

            <Col>
               <Card style={{ width: '18rem', margin: "0 auto" }}>
                  <Card.Img variant="top" src="https://image.freepik.com/free-photo/blank-screen-modern-laptop-computer-with-mouse-small-plant-workspace-desktop_34933-320.jpg" />
                  <Card.Body>
                     <Card.Title>Personal Portfolio</Card.Title>
                     <Card.Subtitle className="mb-2 text-muted"><strong>Technology:</strong> HTML, CSS, JavaScript, React.js, Bootstrap</Card.Subtitle>
                     <Card.Text className="p-0">A portfolio website created with React.js to showcase professional work.</Card.Text>
                     <Button className="mr-2" href="#" variant="info">See demo</Button>
                     <Button href="#" variant="outline-info">Github</Button>
                  </Card.Body>
               </Card>
            </Col>

            <Col>
               <Card style={{ width: '18rem', margin: "0 auto" }}>
                  <Card.Img variant="top" src="https://image.freepik.com/free-photo/blank-screen-modern-laptop-computer-with-mouse-small-plant-workspace-desktop_34933-320.jpg" />
                  <Card.Body>
                     <Card.Title>Coming Soon...</Card.Title>
                     <Card.Subtitle className="mb-2 text-muted"><strong>Technology:</strong> TBD</Card.Subtitle>
                     <Card.Text className="p-0">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Card.Text>
                     <Button className="mr-2" href="#" variant="info">See demo</Button>
                     <Button href="#" variant="outline-info">Github</Button>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
         
      </Container>
   ) 
}; 