import React from "react"; 

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import * as ROUTES from '../../constants/routes'; 

const Navigation = () => (

    <Navbar bg="white" expand="lg" sticky="top" style={{justifyContent: "flex-end"}}>
        {/* <Navbar.Brand
            href={ROUTES.LANDING}
            style={{ fontFamily: "'Parisienne', cursive", fontSize: "2rem" }}
            >Aziza Ismail
            </Navbar.Brand> */}

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" style={{flexGrow: "inherit"}}>
            <Nav className="mr-auto">
                <Nav.Link href={ROUTES.LANDING} className="text-uppercase">home</Nav.Link>
                <Nav.Link href={ROUTES.ABOUT} className="text-uppercase">about</Nav.Link>
                {/* <Nav.Link href={ROUTES.PORTFOLIO}>portfolio</Nav.Link> */}
                <Nav.Link href={ROUTES.RESUME} className="text-uppercase">resume</Nav.Link>
                <Nav.Link href={ROUTES.CONTACT} className="text-uppercase">contact</Nav.Link>
            </Nav>

            {/* <Nav className="ml-auto">
                <Nav.Link href="https://github.com/Aziza-Ismail"><FontAwesomeIcon icon={faGithub} size="2x" /></Nav.Link>
                <Nav.Link href="https://www.linkedin.com/in/azizaismail/"><FontAwesomeIcon icon={faLinkedin} size="2x" /></Nav.Link>
            </Nav> */}
        </Navbar.Collapse>
    </Navbar>
); 

export default Navigation; 
     