import React from "react";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

export default function Resume() {
   return (
      <Container fluid style={{ margin: '25px auto', width: '85%' }}>
         <h1>Resume</h1>
         <hr />
         <div class="inner">
            <section>
               <h1>Employment</h1>
               <p>Nov 2013 - Present <em>| LiteraSeed, LLC | Founder and CEO</em></p>
               <p>Raised $55,000 in pre-seed funding, leading product development and forming strategic collaborations with two health systems. </p>
               <p>Sept 2018 - Present <em>| Valleywise Health | Adjunct Research Associate</em></p>
               <p>Developing and conducting IRB-approved research study measuring the efficacy of LiteraSeed tool's in improving clinical outcomes and patient satisfaction.</p>
               <p>Nov 2018 - Jan 2020 <em>| Macy's | On-Call/Seasonal Fine Jewelry Sales</em></p>
               <p>Provided customer service.</p>
               <p>Sept 2018 - Nov 2018 <em>| Humana Speciality Pharmacy | Patient Care Coordinator</em></p>
               <p>Call center duties, mainly scheduling medication delivery for Humana members.</p>
               <p>Sept 2013 - Mar 2018 <em>| Macy's | Commissioned Sales Associate</em></p>
               <p>Provided customer service.</p>
            </section>
            <section>
               <h1>Volunteer Experience</h1>
               <p>Nov 2018 - Nov 2019 <em>| Banner Health | Volunteer</em></p>
               <p>Jan 2017 - Mar 2018 <em>| International Rescue Committee | Citizenship Class Instructor</em></p>
               <p>Jan 2017 - Mar 2018 <em>| Refugee Focus | Women's Empowerment Computer Class Instructor</em></p>
               <p>Jan 2017 - Mar 2018 <em>| Literacy Volunteers of Maricopa County | Adult Literacy Tutor</em></p>
            </section>
            <section>
               <h1>Education</h1>
               <p>2009 - 2013 <em>| Arizona State University</em></p>
               <p><em>Bachelors, Digital Culture (concentration in design)</em></p>
               <p>Barrett, the Honors College</p>
            </section>
            <section>
               <h1>Technical Skills</h1>
               <ul class="skill-set">
                  <li>Full Stack Web Development</li>
                  <li>Entrepreneurship</li>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                  <li>React</li>
                  <li>Bootstrap</li>
                  <li>Firebase</li>
                  <li>Node JS</li>
               </ul>
            </section>
            <section>
               <h1>References</h1>
               <p>Available upon request</p>
            </section>
            <section>
               <h1>Personal Interests</h1>
               <ul class="skill-set">
                  <li>Social Impact</li>
                  <li>Art</li>
                  <li>Writing</li>
                  <li>Reading</li>
               </ul>
            </section>
         </div>
      </Container>
   )
}; 