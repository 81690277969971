import React from "react";

import Container from "react-bootstrap/Container";

// why is this being called on the landing page? 
export default function About() {
   return (
      <div className="background">
         <section>
            <article>
               <div className="container about-container" article="">
                  <h1>Aziza Ismail</h1>
                  <hr />
                  <div className="vertical-spacing"></div>
                  <h3>Fast facts</h3>
                  <ul>
                     <li>First generation American of Afghan descent, born in NY</li>
                     <li>
                        First and only woman on her mother's side to graduate high school
                        and college, and within the first generation on her father's side
                     </li>
                     <li>
                        Undergraduate thesis in 'Improving Adult Literacy' by designing a
                        mobile literacy application which led to the formation of
                        LiteraSeed
                     </li>
                     <li>
                        Lives life outside of her comfort zone so she can make a
                        difference and inspire others
                     </li>
                  </ul>
                  <div className="vertical-spacing"></div>
                  <h3>A background of adversity</h3>
                  <p>
                     Life, it seems, has never been easy for the Ismail family. Evacuated
                     from their homeland of Afghanistan to escape war during the Soviet
                     invasion, they sought safety and shelter in the USA.
                  </p>
                  <p>
                     While they were lucky to make the journey, on arrival they faced
                     hardship. With limited education history and English language
                     skills, they struggled. Aziza's father worked hard for many years to
                     build security and ensure they could have a better life.
                  </p>
                  <p>
                     Inspired by her father, Aziza worked hard at school. Coming from a
                     conservative culture, though, she was met with heavy opposition from
                     family about continuing her education, rather than pursuing a more
                     traditional path and an arranged marriage. In another cruel blow,
                     the biggest recession in recent history destroyed the American
                     economy. Her father lost nearly everything, including the family
                     business he struggled many years to start. Despite these hardships,
                     Aziza persevered and went onto college, earning honors upon
                     graduation. The resistance she faced, coupled with the now
                     financial challenges and pressures, significantly compounded the 
                     challenges of being a first generation college student. In the
                     midst of it all, she came home to her mother feeling very depressed.
                     Her mother had been thinking about how her father never taught her
                     how to read, and that if she had learned to read she could have
                     helped to make life for herself and her family better. Saddened by
                     this reality, Aziza wanted to look for ways to help. This was the
                     original impetus for what would become Aziza’s life’s work.
                  </p>
                  <p>
                     In 2016, the family suffered a further tragedy with the loss of
                     Aziza's beloved 10 year old cousin to a treatable condition. A
                     communication barrier with doctors caused a delay in the ER, sadly
                     she didn't get the essential treatment fast enough. The effect of
                     losing her has been incredibly difficult to bear.
                  </p>

                  <div className="vertical-spacing"></div>                  
                  <h3>This history opened the door to Aziza's expertise in adult literacy</h3>
                  <p>This disaster marked a turning point for Aziza. She wanted a detailed understanding of the problem that caused delayed treatment for her little cousin, so she could go out and prevent the same thing from happening to others.</p>
                  <p>She learned that language and communication barriers are common, as are understanding barriers stemming from low health literacy. Results of this include incorrect diagnoses, delayed treatment, and death.</p>
                  <p><a href="https://barretthonors.asu.edu/news-events/news/medical-literacy-startup-literaseed-grew-out-barrett-alumna-aziza-ismail%E2%80%99s-honors">Read more about Aziza's work.</a></p>

                  <div className="vertical-spacing"></div>
                  <h3>Strong-willed</h3>
                  <p>Aziza means 'powerful' and 'beloved'. Her parents couldn't have chosen a more appropriate name for her! In her quest to understand she became a single-minded, relentless, powerhouse, and she lives her life with grit and determination.</p>
                  <p>Being this way is not traditional for a woman of Afghan descent. Aziza is paving the way for future generations of women by refusing to conform to cultural restrictions. Although this sometimes causes tension with family, Aziza believes that she can and will make a difference.</p>
                  <p>While she might come from a challenging background, Aziza has a promising future as an influential leader, with the ability to change the world!</p>

                  {/* <div className="vertical-spacing"></div>
                  <h3>Illiteracy and the status of women in Afghanistan</h3>
                  <p></p> */}
               </div>
            </article>
         </section>
      </div>
   )
}; 